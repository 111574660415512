import { useSpring, animated } from 'react-spring'

export default function WelcomeSection() {

    const props = useSpring({
        opacity: 1,
        transform: 'translateX(0%)',
        from: { opacity: 0, transform: 'translateX(-20%)' },
        config: {duration: 500}
      });

    return(
        <div className="bg-white-pattern w-screen
        md:h-96">
            <animated.div style={props}>
                <h1 className="text-dark-purple font-medium text-center w-64 h-96 py-12 mx-auto text-4xl
                md:text-5xl md:py-11 md:w-1/3 md:ml-14 md:text-left">
                    A doceria que prepara sabores com todo cuidado e carinho para você!
                </h1>
            </animated.div>
        </div>
    )
}