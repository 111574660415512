import logoRoxa from '../images/logoRoxa.png'
import MenuBar from './MenuBar.'

import {Link} from 'react-scroll'
import { useState, useEffect } from 'react'

export default function DoceHeader(props) {

    const handleSetLateralBar = () => {
            props.sendToParent(true)
    }

    return(

        <div className="flex justify-around mt-10 text-dark-purple text-xl absolute w-full">

            {props.width >= 810 ? 
                        <h1 className='hover:cursor-pointer hover:text-light-purple'>
                        Pedido
                    </h1> : null}

            {props.width >= 810 ?             
                <h1 className='hover:cursor-pointer hover:text-light-purple'>
                    <Link to="contactSection" spy={true} smooth={true} offset={-200} duration={500}>
                         Contato
                    </Link>
                </h1> : 
                null}

            
            {props.width < 810 ? 
                <span onClick={handleSetLateralBar} className='material-icons absolute left-5 text-3xl hover:cursor-pointer'>
                    menu
                </span> : 
                null }


            <img className='w-52
            md:w-80 -mt-10' src={logoRoxa} alt="logo_doce_lua"/>

            {props.width >= 810 ?             
                <h1 className='hover:cursor-pointer hover:text-light-purple'>
                    <Link to='bioSection' spy={true} smooth={true} offset={-200} duration={500}>
                        História
                    </Link>
                </h1> : 
                null}
                
                
            {props.width >= 810 ?             
                <h1 className='hover:cursor-pointer hover:text-light-purple'>
                    <Link to="doceSection" spy={true} smooth={true} offset={-200} duration={500}>
                        Doces
                    </Link>
                </h1> : 
                null}

        </div>
    )
}