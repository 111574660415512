import {useState, useEffect} from 'react'
import icone_roxo from '../images/icone_roxo.png'
import {Link, Button, Element, Events, animateScroll as scroll, scrollSpy} from 'react-scroll'

export default function DoceFixedHeader(props) {

    const [pageHeight, setPageHeight] = useState()

    const scrollToTop = () => {
        scroll.scrollToTop();
    }

    
    return(
        <div className={`fixed w-full bg-white opacity-95 z-50 flex items-center gap-4 text-dark-purple my-auto h-32 py-20
        ${props.width >= 1013 ? 'md:gap-44' : 'md:gap-24'} md:text-xl md:h-28`}>

        <img className='hover:cursor-pointer w-24
        md:w-40' src={icone_roxo} alt="logo_doce_lua" onClick={scrollToTop}/>

        <h1 className={`hover:cursor-pointer hover:text-light-purple ${props.scroll >= 350 && props.scroll <= 800 ? 'underline' : null}`}>
            <Link to="doceSection" spy={true} smooth={true} offset={-250} duration={500}>
                Doces
            </Link>
        </h1>

        <h1 className={`hover:cursor-pointer hover:text-light-purple ${props.scroll >= 801 && props.scroll < 1500 && props.scroll + props.windowHeight < props.totalHeight ? 'underline' : null}`}>
        <Link to="bioSection" spy={true} smooth={true} offset={-200} duration={500}>
                História
            </Link>
        </h1>

        <h1 className={`hover:cursor-pointer hover:text-light-purple ${props.scroll + props.windowHeight == props.totalHeight ? 'underline' : null}`}>
            <Link to="contactSection" spy={true} smooth={true} offset={-200} duration={500}>
                Contato
            </Link>
        </h1>
        
    </div>
    )
}