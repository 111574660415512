import { useTypewriter, Cursor } from 'react-simple-typewriter'

export default function DoceSection(props) {

    const [variableSweets] = useTypewriter({
        words: ['Copo da Felicidade', 'Bolo de Pote', 'Fatia de Bolo', 'Mousses (Limão, Maracujá, Chocolate, Morango)', 'Trufas', 'Pudim', 'Brownie', 'Brigadeiros' ],
        delaySpeed: 1000,
        loop: {}
    })

    return(
        <div className="w-screen bg-purple-pattern h-96 text-center
        md:h-30rem md:text-left" id='doceSection'>
            <h1 className="text-white font-medium text-3xl py-10
            md:text-4xl md:py-24 md:w-1/3 md:ml-14 md:text-left">
                Confira nossos doces
            </h1>

            <h1 className="text-white font-medium text-3xl
            md:text-5xl md:py-2 md:w-1/3 md:ml-14 underline">
                {variableSweets}
            </h1>
        </div>
    )
}