import instagram_logo from '../images/instagram_icon.svg'
import whatsapp_logo from '../images/whatsapp_icon.svg'

export default function ContactSection() {
    return(
        
        <div id='contactSection' className="w-screen bg-purple-pattern 
        md:h-96">

            <h1 className="text-white font-medium text-center text-2xl w-72 mx-auto py-7
            md:text-4xl md:py-11 md:w-fit">
                Entre em contato com a Doce Lua

            </h1>

            <div className='flex justify-around mt-2
            md:mx-40'>

                <div className='flex flex-col items-left w-fit'>
                    <a href="https://www.instagram.com/doceluamaceio/" target='_blank'>

                        <img src={instagram_logo} alt="instagram_logo" className='mx-auto w-10
                        md:w-20'/>

                        <h2 className='text-white
                        md:text-2xl'>@doceluamaceio</h2>
                    </a>
                </div>
                <div className='flex flex-col items-left w-fit'>
                    <a href="https://wa.me/558291161851" target='_blank'>
                        <img src={whatsapp_logo} alt="whatsapp_logo" className='mx-auto w-10
                        md:w-20 md:-mt-1'/>
                        <h2 className='text-white
                        md:text-2xl md:mt-1'>(82) 99116-1851</h2>
                    </a>
                </div>

            </div>

        </div>
    )
}