import BioSection from "./BioSection"
import ContactSection from "./ContactSection"
import DoceFixedHeader from "./DoceFixedHeader"
import DoceHeader from "./DoceHeader"
import DoceSection from "./DoceSection"
import WelcomeSection from "./WelcomeSection"
import MenuBar from "./MenuBar."

import { useState, useEffect } from 'react'

function LandingPage() {

    const [scrollY, setScrollY] = useState(0)
    const [scollTotal, setScrollTotal] = useState(0)
    const [windowHeight, setWindowHeight] = useState(0)
    const [width, setWidth] = useState(document.documentElement.clientWidth)
    const [lateralBar, setLateralBar] = useState(false)

    const onHandleWidth = () => {
        setWidth(document.documentElement.clientWidth)
    }

    const onHandleLateralBar = (childBoolean) => {
        setLateralBar(childBoolean)
    }
    
    useEffect(() => {
        window.addEventListener('resize', onHandleWidth)
    }, [])

    const handleScrollY = () => {
        setScrollY(document.documentElement.scrollTop)
        setScrollTotal(document.documentElement.scrollHeight)
        setWindowHeight(window.innerHeight)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScrollY)
    }, [])

    return(
        <>

            {lateralBar && width <= 820 ? <MenuBar sendToParent={onHandleLateralBar} lateralBar={lateralBar}/> : null}

            <div className={`w-screen ${lateralBar && width < 768 ? 'opacity-0' : 'opacity-100'}`}>

                {scrollY <= 170 ?  <DoceHeader width={width} sendToParent={onHandleLateralBar} lateralBar={lateralBar}/> : width > 768 ?  <DoceFixedHeader scroll={scrollY} width={width} totalHeight={scollTotal} windowHeight={windowHeight}/> : null}

                 <div className="pt-48">
                     <WelcomeSection/>
                 </div>

                <span className={`bg-light-purple opacity-5 w-screen  ${width >= 810 ? 'h-10' : 'h-5 '}block absolute`}></span>
                <span className={`bg-light-purple opacity-25 w-screen ${width >= 810 ? 'h-14 mt-10': 'h-7 mt-5'} block absolute`}></span>
                <span className={`bg-light-purple opacity-50 w-screen ${width >= 810 ? 'h-20 mt-24': 'h-10 mt-12'} block absolute`}></span>

                <div className={`${width >= 810 ? 'mt-40' : 'mt-20'}`}>
                    <DoceSection scroll={scrollY}/>
                </div>

                <span className={`bg-light-purple opacity-50 w-screen ${width >= 810 ? 'h-10' : 'h-5'} block absolute`}></span>
                <span className={`bg-light-purple opacity-25 w-screen ${width >= 810 ? 'h-14 mt-10' : 'h-7 mt-5'} block absolute`}></span>
                <span className={`bg-light-purple opacity-5 w-screen  ${width >= 810 ? 'h-20 mt-24' : 'h-10 mt-12'} block absolute`}></span>

                <div className={`${width >= 810 ? 'mt-40' : 'mt-20'}`}>
                    <BioSection/>
                </div>

                <span className={`bg-light-purple opacity-5 w-screen  ${width >= 810 ? 'h-10' : 'h-5 '} block absolute`}></span>
                <span className={`bg-light-purple opacity-25 w-screen ${width >= 810 ? 'h-14 mt-10': 'h-7 mt-5'} block absolute`}></span>
                <span className={`bg-light-purple opacity-50 w-screen ${width >= 810 ? 'h-20 mt-24': 'h-10 mt-12'} block absolute`}></span>

                <div className={`${width >= 810 ? 'mt-40' : 'mt-20'}`}>
                    <ContactSection/>
                </div>

            </div> 
        </>
    )
}

export default LandingPage