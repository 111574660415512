import {useState, useEffect} from 'react'
import {Link} from 'react-scroll'

export default function MenuBar(props) {

    const [menuTheme, setmenuTheme] = useState(0)
    const [bgTheme, setBgTheme] = useState('bg-white')
    const [btnTheme, setBtnTheme] = useState('text-white')

    const onHandleCloseMenu = () => {
        props.sendToParent(false)
    }

    useEffect(() => {
        const randomNum = Math.random().toString()

        console.log(randomNum[2])

        if(randomNum[2] % 2 == 0) {
            setBgTheme('bg-white-pattern')
            setBtnTheme('text-dark-purple')
        } else {
            setBgTheme('bg-purple-pattern')
            setBtnTheme('text-white')
        }

    }, [])

    return(
        <div className={`${bgTheme} h-screen w-screen fixed z-50`}>

            <span onClick={onHandleCloseMenu} className={`material-icons text-4xl ${btnTheme} absolute right-11 mt-5`}>close</span>

            <div className={`flex flex-col justify-center h-screen gap-10 text-2xl align-middle -mt-10 font-medium ${btnTheme}`}>

                <Link to="doceSection" spy={true} smooth={true} offset={-250} duration={500}>
                    <h1 onClick={onHandleCloseMenu} className='text-center'>
                        Doces
                    </h1>
                </Link>

                <Link to='bioSection' spy={true} smooth={true} offset={-200} duration={500}>
                    <h1 onClick={onHandleCloseMenu} className='text-center'>
                        História
                    </h1>
                </Link>

                <Link to="contactSection" spy={true} smooth={true} offset={-200} duration={500}>
                    <h1 onClick={onHandleCloseMenu} className='text-center'>
                        Contato
                    </h1>
                </Link>

            </div>

        </div>
    )
}