import './styles/image_fade.css'
const laura_e_fatima = require('../images/laura_e_mamae.jpg')

export default function BioSection() {
    return(
        <div className="bg-white-pattern w-screen
        md:h-fit">

            <div className="flex flex-col align-middle
            md:flex-row">
                <h1 className="text-dark-purple font-medium text-3xl text-center w-80 mx-auto
                md:text-4xl md:w-1/3 md:py-20 md:ml-14 md:text-left" id="bioSection">
                    Minha doce jornada começou na cozinha ao lado da minha mãe, onde cada receita se tornou um pedacinho do nosso amor, 
                    agora compartilhado em cada doce da minha própria doceria, Doce Lua.
                </h1>
                {/* <img className='w-80 mx-auto mt-9 rounded-sm
                md:w-96 md:h-96 md:mr-32 md:my-auto ' src={laura_e_fatima} alt="" srcset=""/> */}

                <div className='fade-border w-72 mx-auto mt-9 rounded-sm
                md:w-96 md:h-96 md:mr-32 md:my-auto'>
                    <img src={laura_e_fatima} alt="" />
                </div>
            </div>
        </div>
    )
}